import React from "react"
import "./ourproducts.sass"
import prod_1 from "../../../static/images/prod_1.jpeg"
import prod_2 from "../../../static/images/prod_2.jpeg"
import prod_3 from "../../../static/images/prod_3.jpeg"
import prod_4 from "../../../static/images/prod_4.jpeg"
import prod_6 from "../../../static/images/prod_6.jpeg"
import prod_7 from "../../../static/images/prod_7.jpeg"
import prod_8 from "../../../static/images/prod_8.jpeg"

const OurProduts = () => (
    <div className="ourproducts">

        <div className="ourproducts__item">
            <div className="ourproducts__item-content-wrap">
                <h3 className="ourproducts__item-title">BOLAS DE KEGEL</h3>
                <p className="ourproducts__item-description">Excelente complemento para realizar los ejercicios de piso pélvico, tonificando y fortaleciendo estos músculos evitando incontinencia urinaria y proporcionando un mejor placer sexual.
                  También son utilizadas en la preparación del embarazo.</p>
             </div>
           <div className="ourproducts__item-img-wrap">
             <img className="ourproducts__item-img" src={prod_1}></img>
           </div>
        </div>

        <div className="ourproducts__item">
            <div className="ourproducts__item-content-wrap">
                <h3 className="ourproducts__item-title">COPA MENSTRUAL</h3>
                <p className="ourproducts__item-description">Accesible, cómoda, económica, segura, evita infecciones, amigable con el medio ambiente y tienen duración hasta 10 años</p>
           </div>
           <div className="ourproducts__item-img-wrap">
             <img class="ourproducts__item-img"src={prod_8}></img>
           </div>
        </div>


        <div className="ourproducts__item">
           <div className="ourproducts__item-img-wrap">
             <img class="ourproducts__item-img"src={prod_3}></img>
           </div>
           <div className="ourproducts__item-content-wrap">
              <h3 className="ourproducts__item-title">SISTEMA INTRAUTERINO</h3>
             <p className="ourproducts__item-description">Colocación de Mirena, Kyleena y Jaydess.</p>
           </div>
           <div className="ourproducts__item-img-wrap">
             <img class="ourproducts__item-img"src={prod_2}></img>
             <img class="ourproducts__item-img"src={prod_4}></img>
           </div>
        </div>

        <div className="ourproducts__item">
          <div className="ourproducts__item-content-wrap">
            <h3 className="ourproducts__item-title">PRUEBA DE VPH</h3>
            <p className="ourproducts__item-description">Detecta la presencia del virus del papiloma humano, un virus que puede provocar el
              desarrollo de verrugas genitales, células cervicales  anormales  o cáncer de cuello uterino.</p>
          </div>
          <div className="ourproducts__item-img-wrap">
            <img class="ourproducts__item-img"src={prod_6}></img>
          </div>
        </div>


      </div>
)
export default OurProduts
