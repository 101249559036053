import React from "react"

import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import OurProducts from "../components/ourproducts/ourproducts"

const NuestrosProductos = () => (
  <Layout>
    <SEO lang="es" description="Nuestros Productos" title="Nuestros Productos" />
    <OurProducts></OurProducts>
  </Layout>
)

export default NuestrosProductos
